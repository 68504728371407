<template>
  <div class="imageBackground">
    <div class="contentContainer">
      <div class="headerContainer">
        <img :src="logo" class="logo" />
      </div>
      <div class="mainContainer">
        <div class="subContainer">
          <h1 class="title">{{ $t("labels.recoverYourAccount") }}</h1>
          <div class="inputRow">
            <custom-input
              type="email"
              :modelValue="email"
              :modelInvalid="invalidEmail"
              @update:modelValue="email = $event"
              @update:modelInvalid="invalidEmail = $event"
              :placeholder="`* ${$t('labels.e_mail')}`"
            />
          </div>
          <div class="inputRow">
            <custom-input
              type="password"
              :modelInvalid="invalidPassword"
              :modelValue="password"
              @update:modelValue="password = $event"
              @update:modelInvalid="invalidPassword = $event"
              :placeholder="`* ${$t('labels.password')}`"
            />
          </div>
          <div class="text-right btn" @click="goToForgotPassword()">
            {{ $t("labels.forgotPassword") }}
          </div>
          <div
            class="text-right btn"
            @click="goToRegisterNow()"
            v-if="isRegister"
          >
            {{ $t("labels.registerNow") }}
          </div>
          <button
            class="authBtnContainer bg-yellow mb-20"
            @click="loginAttempt()"
          >
            {{ $t("labels.submit") }}
          </button>
          <button
            class="socialAuthBtnContainer bg-blue"
            @click="loginWithFacebookAttempt()"
            v-if="isRegister"
          >
            <span class="socialIcon">
              <font-awesome-icon
                :icon="{ prefix: 'fab', iconName: 'facebook' }"
              />
            </span>
            {{ $t("labels.loginWithFacebook") }}
          </button>
          <button
            class="socialAuthBtnContainer bg-pink"
            @click="loginWithGoogleAttempt()"
            v-if="isRegister"
          >
            <span class="socialIcon">
              <font-awesome-icon
                :icon="{ prefix: 'fab', iconName: 'google' }"
              />
            </span>
            {{ $t("labels.loginWithGoogle") }}
          </button>
          <!-- <div class="termsRow">
            <p class="termsDes">
              No tienes cuenta?
              <span class="termsBtn no-border" @click="goToRegister()"
                >Regístrate</span
              >
            </p>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomInput from "../components/CustomInput.vue"
let self
export default {
  name: "LoginPage",
  components: {
    CustomInput,
  },
  data() {
    return {
      logo: require("../assets/image/svg/logo.svg"),
      name: "",
      email: "",
      invalidName: false,
      password: "",
      invalidPassword: false,
      isRegister: process.env.VUE_APP_ENABLE_REGISTER === "true"
    }
  },

  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/")
    },
    goToRegister() {
      this.$router.push("/register")
    },
    goToForgotPassword() {
      this.$router.push("/forgotPassword")
    },
    goToRegisterNow() {
      this.$router.push("/register")
    },
    goToDashboard() {
      this.$router.push("/dashboard")
    },
    loginAttempt() {
      let flag = true
      if (!this.email) {
        flag = false
        this.invalidEmail = true
      }
      if (!this.password) {
        flag = false
        this.invalidPassword = true
      }
      if (!flag) return

      let data = {
        email: this.email,
        password: this.password,
      }

      // let data = {
      //   email: "alex@besukha.com",
      //   password: "Password1",
      // };

      this.$store.dispatch("auth/loginAttempt", data)
    },
    async loginWithFacebookAttempt() {
      self = this
      if (window.FB) {
        window.FB.login(function(response) {
          if (response.authResponse) {
            // alert("You are logged in &amp; cookie set!");
            console.log("fblogin: ", response.authResponse)
            window.FB.api(
              "/me",
              "GET",
              {
                access_token: response.authResponse.accessToken,
                fields: "id,name,gender,email,picture",
              },
              function(res) {
                console.log("facebook user info: ", res)
                let data = {
                  email: res.email,
                  auth_type: "facebook",
                  auth_token: response.authResponse.accessToken,
                }

                self.$store.dispatch("auth/loginAttempt", data)
              }
            )
            // Now you can redirect the user or do an AJAX request to
            // a PHP script that grabs the signed request from the cookie.
          } else {
            // alert("User cancelled login or did not fully authorize.");
          }
        })
      }
    },

    async initFacebook() {
      window.fbAsyncInit = function() {
        window.FB.init({
          appId: "1002345903572729", //You will need to change this
          cookie: true, // This is important, it's not enabled by default
          version: "v10.0",
        })
      }
    },
    async loadFacebookSDK(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src = "https://connect.facebook.net/en_US/sdk.js"
      fjs.parentNode.insertBefore(js, fjs)
    },
    async loginWithGoogleAttempt() {
      try {
        const googleUser = await this.$gAuth.signIn()
        if (!googleUser) {
          return null
        }
        // console.log("googleUser", googleUser);
        this.user = googleUser.getBasicProfile().getEmail()
        // console.log("getId", this.user);
        // console.log("getBasicProfile", googleUser.getBasicProfile());
        console.log("getAuthResponse", googleUser.getAuthResponse())
        // console.log(
        //   "getAuthResponse",
        //   this.$gAuth.instance.currentUser.get().getAuthResponse()
        // );
        let data = {
          email: googleUser.getBasicProfile().getEmail(),
          auth_type: "google",
          auth_token: googleUser.getAuthResponse().access_token,
        }
        console.log("data: ", data)
        this.$store.dispatch("auth/loginAttempt", data)
      } catch (error) {
        //on fail do something
        console.error(error)
        return null
      }
    },

    async handleClickGetAuthCode() {
      try {
        const authCode = await this.$gAuth.getAuthCode()
        console.log("authCode", authCode)
      } catch (error) {
        //on fail do something
        console.error(error)
        return null
      }
    },
  },
  beforeMount: async function() {
    await this.loadFacebookSDK(document, "script", "facebook-jssdk")
    await this.initFacebook()
  },
}
</script>

<style scoped>
.termsRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.termsDes {
  font-size: 13px;
  color: var(--textColor);
}
.termsBtn {
  color: var(--primary);
  border-bottom: 1px solid var(--primary);
  font-size: 15px;
  margin-left: 5px;
  cursor: pointer;
}
.no-border {
  border: none;
}
</style>
