<template>
  <div :class="`inputContainer ${modelInvalid ? 'invalid' : ''} ${className}`">
    <input
      :value="modelValue"
      class="input"
      :type="newtype"
      :placeholder="placeholder"
      @input="handleInput"
    />
    <div @click="showPassword()">
      <font-awesome-icon
        v-if="type == 'password' && newtype != 'password'"
        icon="eye-slash"
        class="inputIcon btn"
      />
      <font-awesome-icon
        v-if="type == 'password' && newtype == 'password'"
        icon="eye"
        class="inputIcon btn"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomInput",
  props: {
    modelValue: { type: String, default: "" },
    modelInvalid: { type: Boolean, default: false },
    placeholder: { type: String, default: "" },
    type: { type: String, default: "" },
    className: { type: [String, Array], default: "" },
  },
  emits: [
    "blur",
    "focus",
    "update:modelValue",
    "validate",
    "update:modelInvalid",
  ],
  data() {
    return {
      newtype: this.type,
    };
  },
  methods: {
    handleInput(e) {
      this.$emit("update:modelValue", e.target.value);
      this.$emit("update:modelInvalid", !e.target.value);
    },
    handleBlur() {
      this.$emit("blur");
      this.handleValidate();
    },
    handleValidate() {
      this.$emit("validate");
    },
    showPassword() {
      if (this.newtype == this.type) {
        this.newtype = "text";
      } else {
        this.newtype = this.type;
      }
    },
  },
};
</script>

